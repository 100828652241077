/*
    Instrução para incluir o botão de impressão:
    Inclua botao-impressao="true" na chamada da template principal

    Exemplo:
    {{#> templates/landing-pages title="TV Câmara" description="Página da TV Câmara" main-heading="TV Câmara" botao-impressao="true" breadcrumbs-itens='[{"rotulo":"Comunicação"}, {"rotulo":"TV Câmara", "link":"tvcamara"}]'}}
 */
(function ($) {

    const WHATSAPP = 1,
          FACEBOOK = 2,
          TWITTER = 3;

    var construirModal = function () {

        var theContainer = $('<div id="share-modal-container">');
        var theModal = $('<div id="modal-share-container" role="dialog" aria-label="Compartilhe" class="modal fade">');
        var theDialog = $('<div role="document" class="modal-dialog">');
        var theContent = $('<div class="modal-content">');
        var theBody = $('<div class="modal-body">');
        var theCloseButton = $('<button type="button" class="close" data-dismiss="modal" aria-label="Fechar"><span aria-hidden="true">×</span></button>');
        var theMessageArea = $('<span class="copy-messages" aria-live="assertive" aria-relevant="additions"></span>');

        theBody.append(theCloseButton)
            .append(theMessageArea);

        theContainer.append(
            theModal.append(
                theDialog.append(
                    theContent.append(
                        theBody)
                )
            )
        );

        return theContainer;

    };

    var getDomain = function (url) {
        if (!url) {
            return '';
        }
        var urlParts = url.replace('http://', '')
            .replace('https://', '')
            .replace('//', '')
            .split(/[/?#]/);
        var hostname = urlParts[0];
        return hostname;
    };

    var getUrlPage = function (url) {
        var hostname = getDomain(url);
        var reProtocol = /^(http:|https:)?\/\//;
        var reDomain = /camara\.((gov)|(leg))\.br$/;
        url = url || '';
        if (url.length && (url.match(reProtocol) && (hostname.match(reDomain)))) {
            return url;
        }
        return document.URL;
    };

    var getPageTitle = function (titulo) {
        if (titulo) {
            return titulo;
        }
        return document.title;
    };

    var configPageSharing = function (elem) {
        if (navigator.share) {
            var share = {
                title:  getPageTitle($(elem).attr('data-title')),
                // solicitada a remoção do texto abaixo
                // text: 'Conteúdo do Portal da Câmara dos Deputados.',
                url: getUrlPage($(elem).attr('data-url-compartilhamento'))
            };
            $(elem).on('click', function(e) {
                e.preventDefault();
                navigator.share(share);
            });
        } else {
            $(elem).on('click', function () {
            var modalContainer = $('#share-modal-container');

            modalContainer.remove();

            modalContainer = construirModal();
            // Obter a url de compartilhamento
            var urlPage = getUrlPage($(this).attr('data-url-compartilhamento')),
                pageTitle = getPageTitle($(this).attr('data-titulo-compartilhamento'));

            // Montar o html de compartilhamento se já não estiver montado
            var estrutura = $('<div class="compartilhamento">');

            estrutura.append('<h2 class="compartilhamento__header">Compartilhe</h2>')
                .append('<div class="compartilhamento__por-redes-sociais">')
                .append('<div class="compartilhamento__por-link">');

            estrutura.find('.compartilhamento__por-redes-sociais')
                .append('<h3>Pelas redes sociais</h3>')
                .append('<ul class="compartilhamento__redes" aria-label="Redes sociais">');

            estrutura.find('.compartilhamento__redes')
                .append('<li><a href="#" onclick="window.open(&#39;https://www.facebook.com/sharer/sharer.php?u=&#39;+encodeURIComponent(&#39;' + urlPage + '&#39;),&#39;facebook-share-dialog&#39;,&#39;width=626,height=436&#39;); return false;" class="compartilhamento__link-facebook">Facebook</a></li>')
                //.append('<li><a href="#" onclick="javascript:window.open(&#39;https://twitter.com/share?text=' + pageTitle + '&amp;url=&#39;+encodeURIComponent(&#39;' + urlPage + '&#39;),&#39;Twitter-dialog&#39;,&#39;width=626,height=436&#39;); return false;" class="compartilhamento__link-twitter">Twitter</a></li>')
                .append('<li><a href="#" onclick="window.open(&#39;https://web.whatsapp.com/send?l=pt_br&amp;text=' + pageTitle + '%20' + urlPage + '&#39;,&#39;Whatsapp-dialog&#39;,&#39;width=750,height=800&#39;); return false;" class="compartilhamento__link-whatsapp">WhatsApp</a></li>');

            estrutura.find('.compartilhamento__por-link')
                .append('<h3>Usando o link</h3>')
                .append('<div class="form-group compartilhamento__copy-link">');

            estrutura.find('.compartilhamento__copy-link')
                .append('<label for="urlPage" class="sr-only">Link para a página</label>')
                .append('<div class="input-group">' +
                    '<input id="urlPage" type="text" class="form-control compartilhamento__copy-url" readonly value="' + urlPage + '">' +
                    '<span class="input-group-btn"><button class="btn button button--light-blue compartilhamento__copy-button" aria-label="Copiar Link da página">Copiar</button></span>' +
                    '</div>');
            estrutura.find('.compartilhamento__copy-button').on('click', function () {

                // Para copiar é necessário um elemento de apoio, uma vez que não é possível fazer
                // a seleção incorporar o valor do input text
                var tempTextArea = document.createElement('textarea');
                var selection = window.getSelection();

                tempTextArea.value = $(this)
                    .closest('.compartilhamento__copy-link')
                    .find('.compartilhamento__copy-url').val();

                // Como o elemento é somente de apoio, deve-se esconde-lo completamente da visualização
                // e evitar o flash da página
                tempTextArea.setAttribute('readonly', 'readonly');
                tempTextArea.style.position = 'absolute';
                tempTextArea.style.opacity = '0';
                document.body.appendChild(tempTextArea);

                // Se houver uma seleção prévia, deve-se respeitar a ação do usuário e preserva-la
                const selected = document.getSelection().rangeCount > 0 ?
                    document.getSelection().getRangeAt(0) :
                    false;

                tempTextArea.select();
                document.execCommand('copy');

                document.body.removeChild(tempTextArea);

                // Restarando a seleção prévia do usuário
                if (selected) {
                    selection.removeAllRanges();
                    selection.addRange(selected);
                }
            });
            modalContainer.find('.modal-body').append(estrutura);
            $('body').append(modalContainer);

            modalContainer.find('[role=dialog]').modal('show');
            });
        }

    };

    var configSharingButton = function (socialMedia, buttonElement) {

        var windowSpecs = 'width=626,height=436';
        var windowName;
        var serviceLink;

        var urlPage = getUrlPage(),
            pageTitle = getPageTitle();

        switch (socialMedia) {
            case WHATSAPP:
                windowName = 'whatsappWindow';
                serviceLink = 'https://web.whatsapp.com/send?l=pt_br&text=' + pageTitle + '%20' + urlPage ;
                break;
            case FACEBOOK:
                windowName = 'facebookWindow';
                serviceLink = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(urlPage);
                break;
            case TWITTER:
               windowName = 'twitterWindow';
               serviceLink = 'https://twitter.com/share?text=' + pageTitle + '&amp;url=' + urlPage;
               break;
            default:
        }
        buttonElement.on('click', function() {
            window.open(serviceLink , windowName, windowSpecs);
        });
    };

    $.fn.configurarCompartilhamento = function () {
        return this.each(function () {
            /*
                Para cada elemento passado associa a ação de abrir a modal ou o widget do dispositivo
            */
           configPageSharing(this);
        });
    };

    $.fn.montarEstruturaCompartilhamento = function() {
        return this.each( function () {
            /* eslint-disable no-undef */
            var estrutura =
               `<aside class="g-l-acoes-apoio" aria-label="Compartilhamento">
                    <div class="g-acoes-apoio g-acoes-apoio--only-small">
                        <button class="g-acoes-apoio__item js-widget-share-button">
                            <i class="fa fa-share-alt g-acoes-apoio__icone" aria-hidden="true"></i>
                            <span class="g-acoes-apoio__chamada-acao">Compartilhe</span>
                        </button>
                    </div>
                    <div class="g-acoes-apoio">
                        <button class="g-acoes-apoio__item js-whatsapp-share-button">
                            <i class="fab fa-whatsapp g-acoes-apoio__icone" aria-hidden="true"></i>
                            <span class="g-acoes-apoio__chamada-acao">Compartilhe com Whatsapp</span>
                        </button>
                    </div>
                    <div class="g-acoes-apoio">
                        <button class="g-acoes-apoio__item js-facebook-share-button">
                            <i class="fab fa-facebook-f g-acoes-apoio__icone" aria-hidden="true"></i>
                            <span class="g-acoes-apoio__chamada-acao">Comparartilhe com Facebook</span>
                        </button>
                    </div>
                    <div class="g-acoes-apoio">
                        <button class="g-acoes-apoio__item js-twitter-share-button">
                            <img src="${$baseUrlTemaWebpack}/assets/images/icone-x-twitter.svg" alt="">
                            <span class="g-acoes-apoio__chamada-acao">Compartilhe com o X</span>
                        </button>
                    </div>
                    
                </aside>`;

            // Para incluir o botão do X, insira o código abaixo dentro da 
            // string da variavel 'estrutura' que está logo acima. Colocar depois
            // da 'div' do Facebook.
            //

            /* esçint-enable */
            $(this).prepend(estrutura);

            if ($(this).hasClass('g-contexto-compartilhamento--extendido') &&
                $(this).hasClass('g-impressao')) {

                var estruturaImpressao =
                    `<div class="g-acoes-apoio g-acoes-apoio--always-visible">
                        <button class="g-acoes-apoio__item js-page-print-button js-acao-impressao">
                            <i class="fas fa-print g-acoes-apoio__icone" aria-hidden="true"></i>
                            <span class="g-acoes-apoio__chamada-acao">Imprimir</span>
                        </button>
                    </div>`;
                $(this).find('.g-l-acoes-apoio').append(estruturaImpressao);
            }
            if ($(this).hasClass('g-contexto-compartilhamento--extendido') &&
                $(this).hasClass('g-comentarios')) {

                var qtdComentarios = (() => $('.comentarios-conteiner').data('quantidade') || 0)();
                var estruturaComentarios =
                `<div class="g-acoes-apoio g-acoes-apoio--always-visible">
                    <a href="#comentario" class="btn g-acoes-apoio__item js-comments-shortcut-button">
                        <i class="fas fa-comments g-acoes-apoio__icone" aria-hidden="true"></i>
                        <span class="g-acoes-apoio__qtd-comentario">${qtdComentarios}</span>
                        <span class="g-acoes-apoio__chamada-acao">Comentários</span>
                    </a>
                </div>`;
                $(this).find('.g-l-acoes-apoio').append(estruturaComentarios);
            }

            $(this).find('.js-widget-share-button').configurarCompartilhamento();

            configSharingButton(WHATSAPP, $(this).find('.js-whatsapp-share-button'));
            configSharingButton(FACEBOOK, $(this).find('.js-facebook-share-button'));
            configSharingButton(TWITTER, $(this).find('.js-twitter-share-button'));
        });
    };

    $.fn.configurarCompartilhamentoComproveHome = function () {
        return this.each(function () {
            $(this).on('click', function () {
                var modalContainer = $('#share-modal-container');

                modalContainer.remove();

                modalContainer = construirModal();
                // Obter a url de compartilhamento
                var urlPage = function (url) {
                        var hostname = getDomain(url);
                        var reProtocol = /^(http:|https:)?\/\//;
                        var reDomain = /camara\.((gov)|(leg))\.br$/;
                        url = url || '';
                        if (url.length && (url.match(reProtocol) && (hostname.match(reDomain)))) {
                            return url;
                        }
                        return document.URL;
                    }($(this).attr('data-url-compartilhamento')),
                    pageTitle = function (titulo) {
                        if (titulo) {
                            return titulo;
                        }
                        return document.title;
                    }($(this).attr('data-titulo-compartilhamento'));

                // modifica url e o titulo para a home do comprove
                const share = this.dataset.shared.split(' ');
                if(share) {
                    // urlPage = window.document.getElementById(share[0]).href;
                    pageTitle = window.document.getElementById(share[2]).textContent;
                    urlPage = window.document.getElementById(share[1]).currentSrc;
                }

                // Montar o html de compartilhamento se já não estiver montado
                var estrutura = $('<div class="compartilhamento">');

                estrutura.append('<h2 class="compartilhamento__header">Compartilhe</h2>')
                    .append('<div class="compartilhamento__por-redes-sociais">')
                    .append('<div class="compartilhamento__por-link">');

                estrutura.find('.compartilhamento__por-redes-sociais')
                    .append('<h3>Pelas redes sociais</h3>')
                    .append('<ul class="compartilhamento__redes" aria-label="Redes sociais">');

                estrutura.find('.compartilhamento__redes')
                    .append('<li><a href="#" onclick="window.open(&#39;https://www.facebook.com/sharer/sharer.php?u=&#39;+encodeURIComponent(&#39;' + urlPage + '&#39;),&#39;facebook-share-dialog&#39;,&#39;width=626,height=436&#39;); return false;" class="compartilhamento__link-facebook">Facebook 2</a></li>')
                    .append('<li><a href="#" onclick="javascript:window.open(&#39;https://twitter.com/share?text=' + pageTitle + '&amp;url=encodeURIComponent(&#39;' + urlPage + '&#39;),&#39;,&#39;Twitter-dialog&#39;,&#39;width=626,height=436&#39;); return false;" class="compartilhamento__link-twitter">Twitter 2</a></li>')
                    .append('<li><a href="#" onclick="window.open(&#39;https://web.whatsapp.com/send?l=pt_br&amp;text=' + pageTitle + '%20' + urlPage + '&#39;,&#39;Whatsapp-dialog&#39;,&#39;width=750,height=800&#39;); return false;" class="compartilhamento__link-whatsapp">WhatsApp 2</a></li>');

                estrutura.find('.compartilhamento__por-link')
                    .append('<h3>Usando o link</h3>')
                    .append('<div class="form-group compartilhamento__copy-link">');

                estrutura.find('.compartilhamento__copy-link')
                    .append('<label for="urlPage" class="sr-only">Link para a página</label>')
                    .append('<div class="input-group">' +
                        '<input id="urlPage" type="text" class="form-control compartilhamento__copy-url" readonly value="' + urlPage + '">' +
                        '<span class="input-group-btn"><button class="btn button button--light-blue compartilhamento__copy-button" aria-label="Copiar Link da página">Copiar</button></span>' +
                        '</div>');
                estrutura.find('.compartilhamento__copy-button').on('click', function () {

                    // Para copiar é necessário um elemento de apoio, uma vez que não é possível fazer
                    // a seleção incorporar o valor do input text
                    var tempTextArea = document.createElement('textarea');
                    var selection = window.getSelection();

                    tempTextArea.value = $(this)
                        .closest('.compartilhamento__copy-link')
                        .find('.compartilhamento__copy-url').val();

                    // Como o elemento é somente de apoio, deve-se esconde-lo completamente da visualização
                    // e evitar o flash da página
                    tempTextArea.setAttribute('readonly', 'readonly');
                    tempTextArea.style.position = 'absolute';
                    tempTextArea.style.opacity = '0';
                    document.body.appendChild(tempTextArea);

                    // Se houver uma seleção prévia, deve-se respeitar a ação do usuário e preserva-la
                    const selected = document.getSelection().rangeCount > 0 ?
                        document.getSelection().getRangeAt(0) :
                        false;

                    tempTextArea.select();
                    document.execCommand('copy');

                    document.body.removeChild(tempTextArea);

                    // Restarando a seleção prévia do usuário
                    if (selected) {
                        selection.removeAllRanges();
                        selection.addRange(selected);
                    }
                });
                modalContainer.find('.modal-body').append(estrutura);
                $('body').append(modalContainer);

                modalContainer.find('[role=dialog]').modal('show');
            });


        });
    };

    $.fn.configurarCompartilharImgem = function () {
        return this.each(function () {
            $(this).on('click', function () {
                var modalContainer = $('#share-modal-container');

                modalContainer.remove();

                modalContainer = construirModal();
                // Obter a url de compartilhamento
                var urlPage = function (url) {
                        var hostname = getDomain(url);
                        var reProtocol = /^(http:|https:)?\/\//;
                        var reDomain = /camara\.((gov)|(leg))\.br$/;
                        url = url || '';
                        if (url.length && (url.match(reProtocol) && (hostname.match(reDomain)))) {
                            return url;
                        }
                        return document.URL;
                    }($(this).attr('data-url-compartilhamento')),
                    pageTitle = function (titulo) {
                        if (titulo) {
                            return titulo;
                        }
                        return document.title;
                    }($(this).attr('data-titulo-compartilhamento'));

                // modifica url e o titulo para a home do comprove
                const share = this.dataset.share;

                if(share) {
                    urlPage = window.document.getElementById(share).currentSrc;
                }


                // Montar o html de compartilhamento se já não estiver montado
                var estrutura = $('<div class="compartilhamento">');

                estrutura.append('<h2 class="compartilhamento__header">Compartilhe</h2>')
                    .append('<div class="compartilhamento__por-redes-sociais">')
                    .append('<div class="compartilhamento__por-link">');

                estrutura.find('.compartilhamento__por-redes-sociais')
                    .append('<h3>Pelas redes sociais</h3>')
                    .append('<ul class="compartilhamento__redes" aria-label="Redes sociais">');

                estrutura.find('.compartilhamento__redes')
                    .append('<li><a href="#" onclick="window.open(&#39;https://www.facebook.com/sharer/sharer.php?u=&#39;+encodeURIComponent(&#39;' + urlPage + '&#39;),&#39;facebook-share-dialog&#39;,&#39;width=626,height=436&#39;); return false;" class="compartilhamento__link-facebook">Facebook 3</a></li>')
                    .append('<li><a href="#" onclick="javascript:window.open(&#39;https://twitter.com/share?text=' + pageTitle + '&amp;url=encodeURIComponent(&#39;' + urlPage + '&#39;),&#39;,&#39;Twitter-dialog&#39;,&#39;width=626,height=436&#39;); return false;" class="compartilhamento__link-twitter">Twitter 3</a></li>')
                    .append('<li><a href="#" onclick="window.open(&#39;https://web.whatsapp.com/send?l=pt_br&amp;text=' + pageTitle + '&amp;url=' + urlPage + '&#39;,&#39;Whatsapp-dialog&#39;,&#39;width=750,height=800&#39;); return false;" class="compartilhamento__link-whatsapp">WhatsApp 3</a></li>');

                estrutura.find('.compartilhamento__por-link')
                    .append('<h3>Usando o link</h3>')
                    .append('<div class="form-group compartilhamento__copy-link">');

                estrutura.find('.compartilhamento__copy-link')
                    .append('<label for="urlPage" class="sr-only">Link para a página</label>')
                    .append('<div class="input-group">' +
                        '<input id="urlPage" type="text" class="form-control compartilhamento__copy-url" readonly value="' + urlPage + '">' +
                        '<span class="input-group-btn"><button class="btn button button--light-blue compartilhamento__copy-button" aria-label="Copiar Link da página">Copiar</button></span>' +
                        '</div>');
                estrutura.find('.compartilhamento__copy-button').on('click', function () {

                    // Para copiar é necessário um elemento de apoio, uma vez que não é possível fazer
                    // a seleção incorporar o valor do input text
                    var tempTextArea = document.createElement('textarea');
                    var selection = window.getSelection();

                    tempTextArea.value = $(this)
                        .closest('.compartilhamento__copy-link')
                        .find('.compartilhamento__copy-url').val();

                    // Como o elemento é somente de apoio, deve-se esconde-lo completamente da visualização
                    // e evitar o flash da página
                    tempTextArea.setAttribute('readonly', 'readonly');
                    tempTextArea.style.position = 'absolute';
                    tempTextArea.style.opacity = '0';
                    document.body.appendChild(tempTextArea);

                    // Se houver uma seleção prévia, deve-se respeitar a ação do usuário e preserva-la
                    const selected = document.getSelection().rangeCount > 0 ?
                        document.getSelection().getRangeAt(0) :
                        false;

                    tempTextArea.select();
                    document.execCommand('copy');

                    document.body.removeChild(tempTextArea);

                    // Restarando a seleção prévia do usuário
                    if (selected) {
                        selection.removeAllRanges();
                        selection.addRange(selected);
                    }
                });
                modalContainer.find('.modal-body').append(estrutura);
                $('body').append(modalContainer);

                modalContainer.find('[role=dialog]').modal('show');
            });


        });
    };

})(jQuery);
